<template>
    <div class="row" v-if="service_provider">  
        <div class="col-12 mb-5">
            <div class="provider">
                <img :src="service_provider.image" alt="">
                <h3 class="title">{{ service_provider.name[lang] }} </h3>
            </div>
            <!-- <p>{{ $t('TextExample') }}</p> -->
        </div>
        <div class="col-md-6">
            <div class="item" v-if="service_provider.is_etmaam == 1">
                <div class="title">{{ $t('FullName') }}</div>
                <div class="value">{{ branch.name }}</div>
            </div>
            <div class="item" v-else>
                <div class="title">{{ $t('CompanyName') }}</div>
                <div class="value">{{ $t('Etmaam For Services') }}</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('PhoneNumber') }}</div>
                <div class="value">{{ branch.phone_number }}</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('Branche') }}</div>
                <div class="value">{{ branch.location }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import Cookie from 'cookie-universal'
const cookie = Cookie()
export default {
    data() {
        return {
            lang:cookie.get('lang')
        };
    },
    props:{
        service_provider:Object,
        branch:Object,
    },
  
}
</script>